* {
  box-sizing: border-box;
}

html,
body,
#__next,
#page {
  height: 100%;
  margin: 0px !important;
}

#page {
  display: flex;
  flex-flow: column;
}
