.heading {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", Ubuntu;
  &--1 {
    font-size: 96px !important;
  }

  &--2 {
    font-size: 60px !important;
  }

  &--3 {
    font-size: 48px !important;
  }

  &--4 {
    font-size: 34px !important;
  }

  &--5 {
    font-size: 24px !important;
  }

  &--6 {
    font-size: 20px !important;
  }
}

.body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", Ubuntu;

  &--1 {
    font-size: 16px;
  }

  &--2 {
    font-size: 14px;
  }
}
