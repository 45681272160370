.content {
  // background-color: $color-background;

  padding: 10px 0px 0px 0px;
  width: 100%;

  @include atMedium {
    padding: 24px 24px 0px 24px;
  }

  &--without-navbar {
    padding-top: 24px;
  }

  &--with-container {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  &--with-banner-container {
    padding: 0px 24px 24px 24px;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  &--with-smaller-container {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  &--with-smallest-container {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  &--h-full {
    height: auto;

    @include atSmall {
      height: 100%;
    }
  }
}
